// React Puro
import React, { useState, useEffect } from "react";

// API_REST
import { AgregarSolicitudProveedor } from "../../../APIS/apiProveedor";

// Material Mui
import {
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  Snackbar,
  Alert,
  styled,
} from "@mui/material";

import { grey } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
/**
 * CSS
 */

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 1
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd",
    borderWidth: 1
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#bdbdbd"
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red"
  }
});


const AgregarViajeProveedor = ({ open, setOpen, folio }) => {

  useEffect(() => {
    if (folio && (folio.id.includes("CHILP") || folio.id.includes("DPSCL") || folio.id.includes("PISCL"))) {
      setId(folio.id);
      setNRT(folio.id);
      setTerminal(folio.Datos.terminal || "");
      setNSolicitud(folio.Datos.nSolicitud || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setComuna(folio.Datos.comuna || "");
      setCounter(folio.Datos.counter || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setPAX(folio.Datos.pax || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setTarifa(folio.Datos.tarifa || "");
      setTotal(folio.Datos.total || "");
      setTotalTyt(folio.Datos.totaltyt || "");
      setCliente(folio.DatosCliente.cliente || "");
      setTelefono(folio.DatosCliente.telefono || "");
      setCorreo(folio.DatosCliente.correo || "");
      setComentario(folio.DatosCliente.comentario || "");
    }
    if (folio && (folio.id.includes("SPA"))) {
      setId(folio.id);
      setNRT(folio.id);
      setNSolicitud(folio.Datos.nSolicitud || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setComuna(folio.Datos.comuna || "");
      setCounter(folio.Datos.counter || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setPAX(folio.Datos.pax || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setTarifa(folio.Datos.tarifa || "");
      setTotal(folio.Datos.total || "");
      setTotalTyt(folio.Datos.totaltyt || "");
      setCliente(folio.DatosCliente.cliente || "");
      setTelefono(folio.DatosCliente.telefono || "");
      setCorreo(folio.DatosCliente.correo || "");
      setComentario(folio.DatosCliente.comentario || "");
    }
    if (folio && folio.id.includes("SE")) {
      setId(folio.id || "");
      setNRT(folio.id || "");
      setPais(folio.Datos.pais || "");
      setTerminal(folio.Datos.terminal || "");
      setNSolicitud(folio.Datos.nSolicitud || "");
      setAeropuerto(folio.Datos.aeropuerto || "");
      setHoraVuelo(folio.Datos.horaVuelo || "");
      setCDistritos(folio.Datos.cDistritos || "");
      setNVoucher(folio.Datos.nVoucher || "");
      setPAX(folio.Datos.pax || "");
      setTipoDeVehiculo(folio.Datos.tipoDeVehiculo || "");
      setNVehiculos(folio.Datos.nVehiculos || "");
      setTotal(folio.Datos.total || "");
      setAerolinea(folio.Datos.aerolinea || "");
      setNVuelo(folio.Datos.nVuelo || "");
      setCounter(folio.Datos.counter || "");
      setSelectedOption(folio.Datos.selectedOption || "");
      setValorVoucher(folio.Datos.valorVoucher || "");
      setTipoDeVehiculoPro(folio.DatosProveedor.tipoDeVehiculoPro || "")
      setNVehiculosPro(folio.DatosProveedor.nVehiculosPro || "");
      setTotalProvee(folio.DatosProveedor.totalProvee || "");
      setComentario(folio.DatosProveedor.comentario || "")
    }
  }, [folio, open, setOpen]);


  const EEstado = [
    { id: 1, label: 'Pendiente' },
    { id: 2, label: 'Cancelado' },
    { id: 3, label: 'Cancelado API' },
    { id: 4, label: 'Completado' },
    { id: 5, label: 'Proximamente' },
    { id: 6, label: 'Activo' },
    { id: 7, label: 'En curso' },
  ];

  // Detalle general Despegar
  const [id, setId] = useState("");
  const [terminal, setTerminal] = useState("");
  const [nrt, setNRT] = useState([]);
  const [nSolicitud, setNSolicitud] = useState("");
  const [comuna, setComuna] = useState(null);
  const [counter, setCounter] = useState("");
  const [horaVuelo, setHoraVuelo] = useState("");
  const [aerolinea, setAerolinea] = useState("");
  const [nVuelo, setNVuelo] = useState("");
  const [pax, setPAX] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [tarifa, setTarifa] = useState("");
  const [total, setTotal] = useState("");
  const [totaltyt, setTotalTyt] = useState("");

  // Detalle General SE
  const [pais, setPais] = useState("");
  const [aeropuerto, setAeropuerto] = useState("");
  const [cDistritos, setCDistritos] = useState("");
  const [nVoucher, setNVoucher] = useState("");
  // NRT
  // Terminal
  // Hora Solicitud
  // Aerolinea
  // N Vuelo
  // Counter
  // PAX
  // Selecd (Tipo de viaje)
  const [tipoDeVehiculo, setTipoDeVehiculo] = useState("");
  const [nVehiculos, setNVehiculos] = useState("");
  const [valorVoucher, setValorVoucher] = useState("");



  const [idd, setIdd] = useState();

  useEffect(() => {
    setIdd(uuidv4());
  }, [open, setOpen]);

  const [Origen, setOrigen] = useState("");
  const [Destino, setDestino] = useState("");
  const [Fecha, setFecha] = useState("");
  const [Hora, setHora] = useState("");
  const [Estado, setEstado] = useState("Por Validar");
  const [VehiculoExtra, setVehiculoExtra] = useState("");
  const [VehiculoNoShow, setVehiculoNoShow] = useState("");

  // Detalle Cliente
  const [cliente, setCliente] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [comentario, setComentario] = useState("");

  // Detalle Proveedor
  const [tipoDeVehiculoPro, setTipoDeVehiculoPro] = useState("");
  const [nVehiculosPro, setNVehiculosPro] = useState("");
  const [totalProvee, setTotalProvee] = useState("");
  // Comentarios

  const [comentarioPanel, setComentarioPanel] = useState("");
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  let Datos = {};
  let Viaje = {};
  let DatosCliente = {};
  let DatosProveedor = {};

  if (folio) {
    if (folio.id.includes("PISCL")) {
      Datos = {
        terminal,
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      }
    } else if (folio.id.includes("DPSCL")) {
      Datos = {
        terminal,
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        tarifa,
        selectedOption,
        total,
        totaltyt,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      }
    } else if (folio.id.includes("CHILP")) {
      Datos = {
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        selectedOption,
        tarifa,
        total,
        totaltyt,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      }
    } else if (folio.id.includes("SPA")) {
      Datos = {
        nrt,
        nSolicitud,
        comuna,
        counter,
        horaVuelo,
        aerolinea,
        nVuelo,
        pax,
        selectedOption,
        tarifa,
        total,
        totaltyt,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      }
    } else if (folio.id.includes("SE")) {
      Datos = {
        pais,
        aeropuerto,
        cDistritos,
        nVoucher,
        nrt,
        terminal,
        horaVuelo,
        aerolinea,
        nVuelo,
        counter,
        pax,
        selectedOption,
        tipoDeVehiculo,
        nVehiculos,
        total,
        totaltyt,
        valorVoucher,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosProveedor = {
        tipoDeVehiculoPro,
        nVehiculosPro,
        totalProvee,
        comentario,
      }
    } else if (folio.id.includes("KPS")) {
      Datos = {
        nrt,
        nSolicitud,
        counter,
        pax,
        selectedOption,
        total,
        totaltyt,
      };
      Viaje = {
        idd,
        Origen,
        Destino,
        Fecha,
        Hora,
        VehiculoExtra,
        VehiculoNoShow,
        Estado,
      };
      DatosCliente = {
        cliente,
        telefono,
        correo,
        comentario,
      }
    }
  }

  const GuardarDatos = async () => {
    if (
      Estado &&
      Origen &&
      Destino &&
      Fecha &&
      Hora
    ) {
      await AgregarSolicitudProveedor(selectedOption, nrt, Viaje, idd);
      setMostrarAlertaExito(true);
      setOpen(false);
    } else {
      setMostrarAlerta(true);
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            p: 3,
            flexGrow: 1,
            width: "70%",
            maxWidth: "500px",
            maxHeight: "80vh",
            overflowY: "auto",
            backgroundColor: "#white",
            elevation: 24,
            borderRadius: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Container maxWidth="xxl">
            <Box
              component="form"
              noValidate
              autoComplete=""
            >
              <Typography
                style={{
                  width: '100%',
                  textAlign: "center",
                  backgroundColor: "rgb(17, 25, 42)",
                  color: "white",
                  borderRadius: "30px",
                }}
                variant="h6"
                fontWeight="bold"

              >
                AGREGAR VIAJE
              </Typography>
              <br />
              <Box
                component="form"
                noValidate
                autoComplete=""
              >
                <Grid item xs={12} sm={6} md={12} container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Origen"
                      variant="outlined"
                      fullWidth
                      value={Origen || ''}
                      error={!Origen || Origen === ''}
                      onChange={(e) => {
                        setOrigen(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      label="Destino"
                      variant="outlined"
                      fullWidth
                      value={Destino || ''}
                      error={!Destino || Destino === ''}
                      onChange={(e) => {
                        setDestino(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="date"
                      label="Fecha"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={Fecha}
                      onChange={(e) => setFecha(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="time"
                      label="Hora"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={Hora}
                      onChange={(e) => setHora(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <p />
              <Grid item xs={12} sm={6} md={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      bgcolor: "#a5d6a7",
                      fontWeight: "bold",
                      color: "green",
                      width: "100%",
                    }}
                    onClick={() => {
                      try {
                        GuardarDatos();
                        setOpen(false);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{
                      bgcolor: "#f8bbd0",
                      fontWeight: "bold",
                      color: "red",
                      width: "100%",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      </Modal>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con exito! , Orden {nrt}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgregarViajeProveedor;