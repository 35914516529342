import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  Paper,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import { grey } from '@mui/material/colors';
import { EditarSolicitudProveedor } from "../../../APIS/apiProveedor";

const AgregarNoShowProveedor = ({ open, setOpen, folio, Idd }) => {

  useEffect(() => {
    const storedFirstname = localStorage.getItem("firstname");
    if (storedFirstname) {
        const currentTime = new Date().toLocaleTimeString(); // Obtiene la hora actual
        const message = `Última actualización realizada por ${storedFirstname} a las ${currentTime}`;
        setUltimaActualizacionNoShow(message);
    }
}, [open]);


  useEffect(() => {
    setNRT(folio.Datos.nrt);
    setSelectedOption(folio.Datos.selectedOption);
    if (folio && folio.Viaje) {
      const viajeEncontrado = folio.Viaje.find((viaje) => viaje.idd === Idd);
      if (viajeEncontrado && viajeEncontrado.DatosProveedor) {
        setVehiculoNoShow(viajeEncontrado.DatosProveedor.VehiculoNoShow || "");
        setComentarioNoShow(viajeEncontrado.DatosProveedor.ComentarioNoShow || "");
      }
    }
  }, [folio, Idd]);

  // Solicitud
  const [nrt, setNRT] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  // Viaje
  const [VehiculoNoShow, setVehiculoNoShow] = useState("");
  const [ComentarioNoShow, setComentarioNoShow] = useState("");
  const [UltimaActualizacionNoShow, setUltimaActualizacionNoShow] = useState("");

  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaExito, setMostrarAlertaExito] = useState(false);

  const GuardarDatos = async () => {
    if (VehiculoNoShow && ComentarioNoShow) {
      try {
        const proveedorData = {
          VehiculoNoShow: VehiculoNoShow,
          ComentarioNoShow: ComentarioNoShow,
          StatusNoShow: "En espera",
          UltimaActualizacionNoShow: UltimaActualizacionNoShow
        };
  
        await EditarSolicitudProveedor(
          selectedOption,
          nrt,           
          Idd,           
          proveedorData  
        );
        setMostrarAlertaExito(true);
        setOpen(false);
  
      } catch (error) {
        console.error('Error saving data:', error);
        setMostrarAlerta(true);
      }
    } else {
      setMostrarAlerta(true);
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            p: 3,
            flexGrow: 1,
            width: "70%",
            maxWidth: "500px",
            maxHeight: "80vh",
            overflowY: "auto",
            backgroundColor: "#white",
            elevation: 24,
            borderRadius: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Container maxWidth="xxl">
            <Box component="form" noValidate autoComplete="">
              <Typography
                style={{
                  width: '100%',
                  textAlign: "center",
                  backgroundColor: "rgb(17, 25, 42)",
                  color: "white",
                  borderRadius: "30px",
                }}
                variant="h6"
                fontWeight="bold"
              >
                NO SHOW
              </Typography>
              <br />
              <Box component="form" noValidate autoComplete="">
                <Grid item xs={12} sm={6} md={12} container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="number"
                      label="Extras"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={VehiculoNoShow}
                      onChange={(e) => setVehiculoNoShow(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      style={{ backgroundColor: grey[100], color: 'white', width: '100%' }}
                      id="outlined-required"
                      type="text"
                      label="Comentario"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={ComentarioNoShow}
                      onChange={(e) => setComentarioNoShow(e.target.value)}
                      multiline
                      maxRows={10}
                    />
                  </Grid>
                </Grid>
              </Box>
              <p />
              <Grid item xs={12} sm={6} md={12} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="success"
                    sx={{
                      bgcolor: "#a5d6a7",
                      fontWeight: "bold",
                      color: "green",
                      width: "100%",
                    }}
                    onClick={() => {
                      try {
                        GuardarDatos();
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{
                      bgcolor: "#f8bbd0",
                      fontWeight: "bold",
                      color: "red",
                      width: "100%",
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Paper>
      </Modal>
      <Snackbar
        open={mostrarAlerta}
        autoHideDuration={3000}
        onClose={() => setMostrarAlerta(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="error" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          Por favor, complete todos los campos obligatorios.
        </Alert>
      </Snackbar>
      <Snackbar
        open={mostrarAlertaExito}
        autoHideDuration={3000}
        onClose={() => setMostrarAlertaExito(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success" style={{ fontWeight: 'bold', border: '1px solid black', borderRadius: '10px' }}>
          ¡Guardado con exito! , Orden {nrt}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgregarNoShowProveedor;
